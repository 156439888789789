import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import ApartmentModal from "./ApartmentModal";
import "../styles/Homepage.css";
import API_BASE_URL from "../config";

const Homepage = () => {
    const { isAdmin } = useContext(AdminContext);
    const [availableApartments, setAvailableApartments] = useState([]);
    const [unavailableApartments, setUnavailableApartments] = useState([]);
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        const fetchApartments = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL}/api/apartments`
                );
                const available = response.data.filter(
                    (apartment) => apartment.available
                );
                const unavailable = response.data.filter(
                    (apartment) => !apartment.available
                );
                setAvailableApartments(available);
                setUnavailableApartments(unavailable);
            } catch (error) {
                console.error("Error fetching apartments", error);
            }
        };

        fetchApartments();
    }, []);

    const openEditModal = (apartment) => {
        setSelectedApartment(apartment);
        setModalType("edit");
        setShowModal(true);
    };

    const openDeleteModal = (apartment) => {
        setSelectedApartment(apartment);
        setModalType("delete");
        setShowModal(true);
    };

    const handleSave = async (payload) => {
        const token = localStorage.getItem("token");
        try {
            if (modalType === "edit") {
                await axios.put(
                    `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setAvailableApartments((prev) =>
                    prev.map((apt) =>
                        apt._id === selectedApartment._id
                            ? { ...apt, ...payload }
                            : apt
                    )
                );
                setUnavailableApartments((prev) =>
                    prev.map((apt) =>
                        apt._id === selectedApartment._id
                            ? { ...apt, ...payload }
                            : apt
                    )
                );
                if (selectedApartment.available !== payload.available) {
                    if (payload.available) {
                        setUnavailableApartments((prev) =>
                            prev.filter(
                                (apt) => apt._id !== selectedApartment._id
                            )
                        );
                        setAvailableApartments((prev) => [
                            ...prev,
                            { ...selectedApartment, ...payload },
                        ]);
                    } else {
                        setAvailableApartments((prev) =>
                            prev.filter(
                                (apt) => apt._id !== selectedApartment._id
                            )
                        );
                        setUnavailableApartments((prev) => [
                            ...prev,
                            { ...selectedApartment, ...payload },
                        ]);
                    }
                }
            } else if (modalType === "create") {
                const response = await axios.post(
                    `${API_BASE_URL}/api/apartments`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setAvailableApartments([...availableApartments, response.data]);
            }
            setShowModal(false);
        } catch (error) {
            console.error("Error saving apartment:", error);
        }
    };

    const handleDelete = async () => {
        const token = localStorage.getItem("token");
        try {
            await axios.delete(
                `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAvailableApartments((prev) =>
                prev.filter((apt) => apt._id !== selectedApartment._id)
            );
            setUnavailableApartments((prev) =>
                prev.filter((apt) => apt._id !== selectedApartment._id)
            );
            setShowModal(false);
        } catch (error) {
            console.error("Error deleting apartment:", error);
        }
    };

    const ApartmentCard = ({ apartment, small = false }) => (
        <div className={`HP-apartment-card ${small ? "HP-small" : "HP-large"}`}>
            <Link to={`/apartments/${apartment._id}`} className='HP-link'>
                <div className='HP-image-container'>
                    <img
                        src={
                            apartment.images?.[0] ||
                            "https://via.placeholder.com/400"
                        }
                        alt={apartment.title}
                    />
                </div>
                <div className='HP-apartment-info'>
                    <h3 className='HP-apartment-title'>{apartment.title}</h3>
                    <p className='HP-apartment-location'>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                        {apartment.location}
                    </p>
                    <p className='HP-apartment-price'>
                        €{apartment.price} / Nacht
                    </p>
                    <div
                        className={`HP-availability-status ${
                            apartment.available
                                ? "HP-available"
                                : "HP-unavailable"
                        }`}
                    ></div>
                </div>
            </Link>
            {isAdmin && (
                <div className='HP-action-buttons'>
                    <button onClick={() => openEditModal(apartment)}>
                        Bearbeiten
                    </button>
                    <button
                        className='HP-delete-btn'
                        onClick={() => openDeleteModal(apartment)}
                    >
                        Löschen
                    </button>
                </div>
            )}
        </div>
    );

    return (
        <>
            <Navbar />
            <div className='HP-homepage-container'>
                <div className='HP-available-apartments'>
                    <h2 style={{ marginBlockEnd: ".75rem" }}>
                        Verfügbare Apartments
                    </h2>
                    <div className='HP-apartments-flex'>
                        {availableApartments.map((apartment) => (
                            <ApartmentCard
                                key={apartment._id}
                                apartment={apartment}
                                small={false}
                            />
                        ))}
                    </div>
                </div>

                <div className='HP-unavailable-apartments'>
                    <h2 style={{ marginBlockEnd: ".75rem" }}>
                        Belegte Apartments
                    </h2>
                    <div className='HP-apartments-list'>
                        {unavailableApartments.map((apartment) => (
                            <ApartmentCard
                                key={apartment._id}
                                apartment={apartment}
                                small={true}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {showModal && (
                <ApartmentModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    apartment={selectedApartment}
                    modalType={modalType}
                    onSave={handleSave}
                    onDelete={handleDelete}
                />
            )}
        </>
    );
};

export default Homepage;
