// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import "../styles/AdminDashboard.css";
// import Navbar from "./Navbar";
// import API_BASE_URL from "../config";

// const AdminDashboard = () => {
//     const [apartments, setApartments] = useState([]);
//     const [showModal, setShowModal] = useState(false);
//     const [confirmModal, setConfirmModal] = useState(false);
//     const [modalType, setModalType] = useState("");
//     const [selectedApartment, setSelectedApartment] = useState(null);

//     // Initialize states for form fields
//     const [title, setTitle] = useState("");
//     const [description, setDescription] = useState("");
//     const [price, setPrice] = useState("");
//     const [location, setLocation] = useState("");
//     const [available, setAvailable] = useState(true);
//     const [occupiedDates, setOccupiedDates] = useState({ start: "", end: "" }); // New state for occupied dates

//     // Fetch apartments on component mount
//     useEffect(() => {
//         const fetchApartments = async () => {
//             const token = localStorage.getItem("token");
//             if (!token) {
//                 console.error("No token found, authorization required");
//                 return;
//             }

//             try {
//                 const response = await axios.get(
//                     `${API_BASE_URL}/api/apartments`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                         },
//                     }
//                 );
//                 setApartments(response.data);
//             } catch (error) {
//                 console.error("Apartments konnten :", error);
//             }
//         };

//         fetchApartments();
//     }, []);

//     // Open edit modal with pre-filled apartment data
//     const openEditModal = (apartment) => {
//         setSelectedApartment(apartment);
//         setTitle(apartment.title);
//         setDescription(apartment.description);
//         setPrice(apartment.price);
//         setLocation(apartment.location || ""); // Set location
//         setAvailable(apartment.available); // Set availability
//         // Check if occupiedDates exists
//         setOccupiedDates(apartment.occupiedDates || { start: "", end: "" }); // Set occupied dates
//         setModalType("edit");
//         setShowModal(true);
//     };

//     // // Open create modal with default values
//     // const openCreateModal = () => {
//     //     setSelectedApartment(null); // Reset for new apartment
//     //     setTitle("");
//     //     setDescription("");
//     //     setPrice("");
//     //     setLocation(""); // Reset location
//     //     setAvailable(true); // Default to true
//     //     setOccupiedDates({ start: "", end: "" }); // Reset occupied dates
//     //     setModalType("create");
//     //     setShowModal(true);
//     // };

//     // Open delete confirmation modal
//     const openDeleteModal = (apartment) => {
//         setSelectedApartment(apartment);
//         setModalType("delete");
//         setConfirmModal(true);
//     };

//     // Handle apartment deletion
//     const handleDeleteApartment = async () => {
//         const token = localStorage.getItem("token");
//         try {
//             await axios.delete(
//                 `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 }
//             );
//             setApartments(
//                 apartments.filter(
//                     (apartment) => apartment._id !== selectedApartment._id
//                 )
//             );
//             setConfirmModal(false); // Close confirmation modal
//         } catch (error) {
//             console.error("Error deleting apartment:", error);
//         }
//     };

//     // Handle creating or updating an apartment
//     const handleSaveApartment = async (e) => {
//         e.preventDefault();
//         const token = localStorage.getItem("token");
//         const payload = {
//             title,
//             description,
//             price,
//             location,
//             available,
//             occupiedDates: available ? null : occupiedDates, // Send occupiedDates only if not available
//         };

//         try {
//             if (modalType === "edit") {
//                 // Edit existing apartment
//                 const response = await axios.put(
//                     `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
//                     payload,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                             "Content-Type": "application/json",
//                         },
//                     }
//                 );
//                 setApartments(
//                     apartments.map((apartment) =>
//                         apartment._id === selectedApartment._id
//                             ? response.data
//                             : apartment
//                     )
//                 );
//             } else if (modalType === "create") {
//                 // Create new apartment
//                 const response = await axios.post(
//                     `${API_BASE_URL}/api/apartments`,
//                     payload,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${token}`,
//                             "Content-Type": "application/json",
//                         },
//                     }
//                 );
//                 setApartments([...apartments, response.data]);
//             }
//             setShowModal(false); // Close modal after saving
//         } catch (error) {
//             console.error("Error saving apartment:", error);
//         }
//     };

//     return (
//         <>
//             <Navbar />
//             <div className='dashboard-container'>
//                 <header className='dashboard-header'>
//                     <h1>Admin Dashboard</h1>
//                     {/* <button className='create-btn' onClick={openCreateModal}>
//                         + Neues Apartment
//                     </button> */}
//                 </header>

//                 <div className='apartment-list'>
//                     {apartments.length > 0 ? (
//                         apartments.map((apartment) => (
//                             <div className='apartment-item' key={apartment._id}>
//                                 <h3>{apartment.title}</h3>
//                                 <p>{apartment.description}</p>
//                                 <span className='price'>
//                                     Preis p. Nacht €{apartment.price}
//                                 </span>
//                                 <p>
//                                     <strong>Adresse:</strong>{" "}
//                                     {apartment.location}
//                                 </p>
//                                 <p>
//                                     <strong>Verfügbarkeit:</strong>{" "}
//                                     <span
//                                         className={
//                                             apartment.available
//                                                 ? "available"
//                                                 : "unavailable"
//                                         }
//                                     >
//                                         {apartment.available
//                                             ? "Verfügbar"
//                                             : "Belegt"}
//                                     </span>
//                                 </p>
//                                 <p>
//                                     <strong>Erstellt:</strong>{" "}
//                                     {new Date(
//                                         apartment.createdAt
//                                     ).toLocaleString()}
//                                 </p>
//                                 {/* Conditional rendering of occupied dates */}
//                                 {/* {!apartment.available &&
//                                     apartment.occupiedDates && (
//                                         <p>
//                                             <strong>Belegt von:</strong>{" "}
//                                             {apartment.occupiedDates.start} bis{" "}
//                                             {apartment.occupiedDates.end}
//                                         </p>
//                                     )} */}
//                                 <button
//                                     className='edit-btn'
//                                     onClick={() => openEditModal(apartment)}
//                                 >
//                                     Bearbeiten
//                                 </button>
//                                 <button
//                                     className='delete-btn'
//                                     onClick={() => openDeleteModal(apartment)}
//                                 >
//                                     Löschen
//                                 </button>
//                             </div>
//                         ))
//                     ) : (
//                         <p>Keine Apartments verfügbar. Erstelle ein neues.</p>
//                     )}
//                 </div>

//                 {showModal && (
//                     <div className='modal-overlay'>
//                         <div className='modal-content'>
//                             <h3>
//                                 {modalType === "edit"
//                                     ? "Apartment bearbeiten"
//                                     : "Neues Apartment erstellen"}
//                             </h3>
//                             <form onSubmit={handleSaveApartment}>
//                                 <input
//                                     type='text'
//                                     placeholder='Titel'
//                                     value={title}
//                                     onChange={(e) => setTitle(e.target.value)}
//                                     required
//                                 />
//                                 <input
//                                     type='text'
//                                     placeholder='Preis'
//                                     value={price}
//                                     onChange={(e) => setPrice(e.target.value)}
//                                     required
//                                 />
//                                 <input
//                                     type='text'
//                                     placeholder='Beschreibung'
//                                     value={description}
//                                     onChange={(e) =>
//                                         setDescription(e.target.value)
//                                     }
//                                     required
//                                 />
//                                 <input
//                                     type='text'
//                                     placeholder='Adresse'
//                                     value={location}
//                                     onChange={(e) =>
//                                         setLocation(e.target.value)
//                                     }
//                                     required
//                                 />
//                                 <div>
//                                     <label>
//                                         <input
//                                             type='radio'
//                                             value='available'
//                                             checked={available}
//                                             onChange={() => {
//                                                 setAvailable(true);
//                                                 setOccupiedDates({
//                                                     start: "",
//                                                     end: "",
//                                                 }); // Reset occupied dates if available
//                                             }}
//                                         />
//                                         Verfügbar
//                                     </label>
//                                     <label>
//                                         <input
//                                             type='radio'
//                                             value='unavailable'
//                                             checked={!available}
//                                             onChange={() => {
//                                                 setAvailable(false);
//                                             }}
//                                         />
//                                         Belegt
//                                     </label>
//                                 </div>

//                                 {/* Show date inputs only when the apartment is unavailable */}
//                                 {/* {!available && (
//                                     <div>
//                                         <label>Start Date:</label>
//                                         <input
//                                             type='date'
//                                             value={occupiedDates.start}
//                                             onChange={(e) =>
//                                                 setOccupiedDates((prev) => ({
//                                                     ...prev,
//                                                     start: e.target.value,
//                                                 }))
//                                             }
//                                         />
//                                         <label>End Date:</label>
//                                         <input
//                                             type='date'
//                                             value={occupiedDates.end}
//                                             onChange={(e) =>
//                                                 setOccupiedDates((prev) => ({
//                                                     ...prev,
//                                                     end: e.target.value,
//                                                 }))
//                                             }
//                                         />
//                                     </div>
//                                 )} */}

//                                 <button type='submit' className='confirm-btn'>
//                                     Speichern
//                                 </button>
//                                 <button
//                                     className='cancel-btn'
//                                     onClick={() => setShowModal(false)}
//                                 >
//                                     Abbrechen
//                                 </button>
//                             </form>
//                         </div>
//                     </div>
//                 )}

//                 {confirmModal && (
//                     <div className='modal-overlay'>
//                         <div className='modal-content'>
//                             <h3>
//                                 Bist du sicher, dass du das Apartment{" "}
//                                 {modalType === "delete"
//                                     ? "löschen"
//                                     : "speichern"}{" "}
//                                 willst?
//                             </h3>
//                             <button
//                                 className='confirm-btn'
//                                 onClick={
//                                     modalType === "delete"
//                                         ? handleDeleteApartment
//                                         : handleSaveApartment
//                                 }
//                             >
//                                 Ja
//                             </button>
//                             <button
//                                 className='cancel-btn'
//                                 onClick={() => setConfirmModal(false)}
//                             >
//                                 Nein
//                             </button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </>
//     );
// };

import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/AdminDashboard.css";
import Navbar from "./Navbar";
import API_BASE_URL from "../config";

const AdminDashboard = () => {
    const [apartments, setApartments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [selectedApartment, setSelectedApartment] = useState(null);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [location, setLocation] = useState("");
    const [available, setAvailable] = useState(true);
    const [occupiedDates, setOccupiedDates] = useState({ start: "", end: "" });

    useEffect(() => {
        const fetchApartments = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found, authorization required");
                return;
            }

            try {
                const response = await axios.get(
                    `${API_BASE_URL}/api/apartments`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setApartments(response.data);
            } catch (error) {
                console.error(
                    "Apartments konnten nicht geladen werden:",
                    error
                );
            }
        };

        fetchApartments();
    }, []);

    const openEditModal = (apartment) => {
        setSelectedApartment(apartment);
        setTitle(apartment.title);
        setDescription(apartment.description);
        setPrice(apartment.price);
        setLocation(apartment.location || "");
        setAvailable(apartment.available);
        setOccupiedDates(apartment.occupiedDates || { start: "", end: "" });
        setModalType("edit");
        setShowModal(true);
    };

    const openDeleteModal = (apartment) => {
        setSelectedApartment(apartment);
        setModalType("delete");
        setConfirmModal(true);
    };

    const handleDeleteApartment = async () => {
        const token = localStorage.getItem("token");
        try {
            await axios.delete(
                `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setApartments(
                apartments.filter(
                    (apartment) => apartment._id !== selectedApartment._id
                )
            );
            setConfirmModal(false);
        } catch (error) {
            console.error("Error deleting apartment:", error);
        }
    };

    const handleSaveApartment = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        const payload = {
            title,
            description,
            price,
            location,
            available,
            occupiedDates: available ? null : occupiedDates,
        };

        try {
            if (modalType === "edit") {
                const response = await axios.put(
                    `${API_BASE_URL}/api/apartments/${selectedApartment._id}`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setApartments(
                    apartments.map((apartment) =>
                        apartment._id === selectedApartment._id
                            ? response.data
                            : apartment
                    )
                );
            } else if (modalType === "create") {
                const response = await axios.post(
                    `${API_BASE_URL}/api/apartments`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                setApartments([...apartments, response.data]);
            }
            setShowModal(false);
        } catch (error) {
            console.error("Error saving apartment:", error);
        }
    };

    return (
        <>
            <Navbar />
            <div className='AD-dashboard-container'>
                <header className='AD-dashboard-header'>
                    <h1>Admin Dashboard</h1>
                </header>

                <div className='AD-apartment-list'>
                    {apartments.length > 0 ? (
                        apartments.map((apartment) => (
                            <div
                                className='AD-apartment-item'
                                key={apartment._id}
                            >
                                <h3>{apartment.title}</h3>
                                <p>{apartment.description}</p>
                                <span className='AD-price'>
                                    €{apartment.price} / Nacht
                                </span>
                                <p>
                                    <strong>Adresse:</strong>{" "}
                                    {apartment.location}
                                </p>
                                <p>
                                    <strong>Verfügbarkeit:</strong>{" "}
                                    <span
                                        className={
                                            apartment.available
                                                ? "AD-available"
                                                : "AD-unavailable"
                                        }
                                    >
                                        {apartment.available
                                            ? "Verfügbar"
                                            : "Belegt"}
                                    </span>
                                </p>
                                <p>
                                    <strong>Erstellt:</strong>{" "}
                                    {new Date(
                                        apartment.createdAt
                                    ).toLocaleString()}
                                </p>
                                <button
                                    className='AD-edit-btn'
                                    onClick={() => openEditModal(apartment)}
                                >
                                    Bearbeiten
                                </button>
                                <button
                                    className='AD-delete-btn'
                                    onClick={() => openDeleteModal(apartment)}
                                >
                                    Löschen
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>Keine Apartments verfügbar. Erstelle ein neues.</p>
                    )}
                </div>

                {/* Modal for edit and create */}
                {showModal && (
                    <div
                        className='AD-modal-overlay'
                        onClick={() => setShowModal(false)}
                    >
                        <div
                            className='AD-modal-content'
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h3>
                                {modalType === "edit"
                                    ? "Apartment bearbeiten"
                                    : "Neues Apartment erstellen"}
                            </h3>
                            <form onSubmit={handleSaveApartment}>
                                <input
                                    type='text'
                                    placeholder='Titel'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                                <input
                                    type='text'
                                    placeholder='Preis'
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                    required
                                />
                                <input
                                    type='text'
                                    placeholder='Beschreibung'
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    required
                                />
                                <input
                                    type='text'
                                    placeholder='Adresse'
                                    value={location}
                                    onChange={(e) =>
                                        setLocation(e.target.value)
                                    }
                                    required
                                />
                                <div>
                                    <label>
                                        <input
                                            type='radio'
                                            value='available'
                                            checked={available}
                                            onChange={() => {
                                                setAvailable(true);
                                                setOccupiedDates({
                                                    start: "",
                                                    end: "",
                                                });
                                            }}
                                        />
                                        Verfügbar
                                    </label>
                                    <label>
                                        <input
                                            type='radio'
                                            value='unavailable'
                                            checked={!available}
                                            onChange={() => {
                                                setAvailable(false);
                                            }}
                                        />
                                        Belegt
                                    </label>
                                </div>

                                <div className='AD-button-container'>
                                    <button
                                        type='submit'
                                        className='AD-confirm-btn'
                                    >
                                        Speichern
                                    </button>
                                    <button
                                        className='AD-cancel-btn'
                                        onClick={() => setShowModal(false)}
                                    >
                                        Abbrechen
                                    </button>
                                </div>
                                <button
                                    className='AD-close-button'
                                    onClick={() => setShowModal(false)}
                                >
                                    X
                                </button>
                            </form>
                        </div>
                    </div>
                )}

                {/* Confirm Modal for delete */}
                {confirmModal && (
                    <div
                        className='AD-modal-overlay'
                        onClick={() => setConfirmModal(false)}
                    >
                        <button
                            className='AD-close-button'
                            onClick={() => setShowModal(false)}
                        >
                            X
                        </button>
                        <div
                            className='AD-modal-content'
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h3>
                                Bist du sicher, dass du das Apartment{" "}
                                {modalType === "delete"
                                    ? "löschen"
                                    : "speichern"}{" "}
                                willst?
                            </h3>
                            <button
                                className='AD-confirm-btn'
                                onClick={
                                    modalType === "delete"
                                        ? handleDeleteApartment
                                        : handleSaveApartment
                                }
                            >
                                Ja
                            </button>
                            <button
                                className='AD-cancel-btn'
                                onClick={() => setConfirmModal(false)}
                            >
                                Nein
                            </button>
                            <button
                                className='AD-close-button'
                                onClick={() => setConfirmModal(false)}
                            >
                                X
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default AdminDashboard;
