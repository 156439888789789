import React from "react";
import Navbar from "./Navbar";
import "../styles/AboutPage.css";

const AboutPage = () => (
    <>
        <Navbar />
        <div className='about-container'>
            <h2>About Us</h2>
            <p className='about-text'>
                We provide high-quality apartments for rent in the city center.
                With years of experience, we ensure the best services for our
                tenants.
            </p>
        </div>
    </>
);

export default AboutPage;
