import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../config";

const EditApartment = () => {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchApartment = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(
                    `${API_BASE_URL}/api/apartments/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const { title, description, price, imageUrl } = response.data;
                setTitle(title);
                setDescription(description);
                setPrice(price);
                setImageUrl(imageUrl || ""); // If no image URL, default to empty string
            } catch (error) {
                setError("Error fetching apartment data.");
            }
        };
        fetchApartment();
    }, [id]);

    const handleUpdateApartment = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("token");
            await axios.put(
                `${API_BASE_URL}/api/apartments/${id}`,
                {
                    title,
                    description,
                    price: Number(price),
                    imageUrl,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            navigate("/admin/dashboard");
        } catch (error) {
            console.error("Error updating apartment:", error);
            setError("Failed to update apartment.");
        }
    };

    return (
        <div>
            <h2>Apartment bearbeiten</h2>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <form onSubmit={handleUpdateApartment}>
                <input
                    type='text'
                    placeholder='Title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <input
                    type='text'
                    placeholder='Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
                <input
                    type='number'
                    placeholder='Price'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                />
                <input
                    type='text'
                    placeholder='Image URL'
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                />
                <button type='submit'>Update Apartment</button>
            </form>
        </div>
    );
};

export default EditApartment;
