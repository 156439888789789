import React from "react";
import Navbar from "../components/Navbar";
import "../styles/PrivacyPolicy.css"; // Importiere die CSS-Datei für Styles

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <div className='PP-container'>
                <div className='PP-content'>
                    <h1 className='PP-title'>Datenschutzerklärung</h1>

                    <p className='PP-contact-info'>
                        Giovannis Apartments <br />
                        Inhaber: Giovanni Vernino <br />
                        Adresse: Bürgermeister-Wohlfarth-Straße 89, 86343
                        Königsbrunn <br />
                        E-Mail: giovannisapartments@gmail.com
                    </p>

                    <h2 className='PP-subtitle'>
                        1. Erhebung und Nutzung personenbezogener Daten
                    </h2>
                    <p className='PP-section'>
                        Im Rahmen der Buchung verarbeiten wir folgende
                        personenbezogene Daten: Name, E-Mail-Adresse,
                        Telefonnummer sowie Buchungsdetails.
                    </p>
                    <p className='PP-section'>
                        <strong>Zahlungsdaten:</strong> <br />
                        Die Zahlungsabwicklung erfolgt über PayPal. Die
                        Verarbeitung der Zahlungsdaten unterliegt den{" "}
                        <a
                            href='https://www.paypal.com/de/webapps/mpp/ua/privacy-full'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Datenschutzbestimmungen von PayPal
                        </a>
                        . Wir speichern selbst keine Zahlungsdaten.
                    </p>
                    <p className='PP-section'>
                        <strong>Bestätigungs-E-Mail:</strong> <br />
                        Nach Ihrer Buchung erhalten Sie eine Bestätigung per
                        E-Mail.
                    </p>

                    <h2 className='PP-subtitle'>2. Weitergabe von Daten</h2>
                    <p className='PP-section'>
                        Ihre personenbezogenen Daten werden ausschließlich zur
                        Abwicklung Ihrer Buchung an PayPal weitergegeben. Eine
                        darüber hinausgehende Weitergabe an Dritte erfolgt
                        nicht.
                    </p>

                    <h2 className='PP-subtitle'>3. Cookies</h2>
                    <p className='PP-section'>
                        Wir verwenden ausschließlich technisch notwendige
                        Cookies, die für die Funktionsfähigkeit unserer Website
                        erforderlich sind. Es werden keine Marketing-, Tracking-
                        oder Analyse-Cookies eingesetzt.
                    </p>

                    <h2 className='PP-subtitle'>4. Rechte der Nutzer</h2>
                    <p className='PP-section'>
                        Sie haben das Recht, Auskunft über die von uns
                        gespeicherten personenbezogenen Daten zu verlangen.
                        Zudem können Sie unrichtige Daten berichtigen lassen und
                        die Löschung Ihrer Daten verlangen, sofern keine
                        gesetzlichen Aufbewahrungspflichten dem entgegenstehen.
                    </p>

                    <h2 className='PP-subtitle'>5. Datensicherheit</h2>
                    <p className='PP-section'>
                        Wir setzen technische und organisatorische Maßnahmen
                        ein, um Ihre Daten vor unberechtigtem Zugriff und
                        Missbrauch zu schützen. Trotz regelmäßiger Kontrollen
                        ist ein vollständiger Schutz gegen alle Gefahren jedoch
                        nicht möglich.
                    </p>

                    <h2 className='PP-subtitle'>
                        6. Änderungen dieser Datenschutzerklärung
                    </h2>
                    <p className='PP-section'>
                        Wir behalten uns das Recht vor, diese
                        Datenschutzerklärung jederzeit zu ändern. Die jeweils
                        aktuelle Version ist auf unserer Website einsehbar.
                    </p>

                    <p className='PP-paragraph'>Stand: 11.10.2024</p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
