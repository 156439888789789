// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.css"; // Import your CSS for styling

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-content'>
                <nav className='footer-nav'>
                    <ul>
                        <li>
                            <Link to='/'>Startseite</Link>
                        </li>
                        <li>
                            <Link to='/kontakt'>Kontakt</Link>
                        </li>
                        <li>
                            <Link to='/datenschutz'>Datenschutz</Link>
                        </li>
                        <li>
                            <Link to='/agb'>AGB</Link>
                        </li>
                    </ul>
                </nav>
                <div className='footer-copyright'>
                    <p>
                        &copy; {new Date().getFullYear()} Giovannis Apartments.
                        Alle Rechte vorbehalten.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
