import Navbar from "./Navbar";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styles/ContactPage.css"; // Add custom styling for layout and design

function ContactPage() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        // Replace with your EmailJS Service ID, Template ID, and User ID
        emailjs
            .sendForm(
                "YOUR_SERVICE_ID",
                "YOUR_TEMPLATE_ID",
                e.target,
                "YOUR_USER_ID"
            )
            .then(
                (result) => {
                    console.log(result.text);
                    alert("Message sent successfully!");
                },
                (error) => {
                    console.log(error.text);
                    alert(
                        "Failed to send the message. Please try again later."
                    );
                }
            );

        e.target.reset(); // Clear form after submission
    };

    return (
        <>
            <Navbar />
            <div className='CP-contact-page'>
                <h2 className='CP-contact-heading'>Kontaktieren Sie uns</h2>
                <div className='CP-contact-content'>
                    <form
                        className='CP-contact-form'
                        onSubmit={sendEmail}
                        aria-label='Contact Form'
                    >
                        <div className='CP-form-group'>
                            <label htmlFor='name'>Name</label>
                            <input
                                type='text'
                                id='name'
                                name='name'
                                value={formData.name}
                                onChange={handleChange}
                                required
                                aria-required='true'
                                placeholder='Name'
                            />
                        </div>

                        <div className='CP-form-group'>
                            <label htmlFor='email'>Email</label>
                            <input
                                type='email'
                                id='email'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                                aria-required='true'
                                placeholder='Email'
                            />
                        </div>

                        <div className='CP-form-group'>
                            <label htmlFor='message'>Nachricht</label>
                            <textarea
                                id='message'
                                name='message'
                                rows='5'
                                value={formData.message}
                                onChange={handleChange}
                                required
                                aria-required='true'
                                placeholder='Ihre Nachricht'
                            ></textarea>
                        </div>

                        <button type='submit'>Nachricht senden</button>
                    </form>

                    {/* Divider */}
                    <div className='CP-vertical-divider'></div>

                    <div className='CP-contact-info'>
                        <h3 className='CP-contact-info-heading'>
                            Weitere Informationen
                        </h3>
                        <p className='CP-contact-info-subheading'>
                            Sie können uns auch gerne per Email oder Telefon
                            erreichen.
                        </p>
                        <p className='CP-contact-info-item'>
                            <span className='CP-contact-info-item-label'>
                                Telefon:
                            </span>{" "}
                            <a
                                href='tel:08231 9184477'
                                className='CP-callable-number'
                                aria-label='Call us at 08231 9184477'
                            >
                                0151 68696510
                            </a>
                        </p>
                        <p className='CP-contact-info-item'>
                            <span className='CP-contact-info-item-label'>
                                Email:
                            </span>{" "}
                            giovannisapartments@gmail.com
                        </p>
                        <p className='CP-contact-info-item'>
                            <span className='CP-contact-info-item-label'>
                                Adresse:
                            </span>{" "}
                            Bürgermeister-Wohlfarth-Straße 89, 86343 Königsbrunn
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactPage;
