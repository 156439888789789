// import React, { useState, useContext } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { AdminContext } from "../context/AdminContext";
// import "../styles/AdminLogin.css";
// import API_BASE_URL from "../config";

// const AdminLogin = () => {
//     const [username, setUsername] = useState("");
//     const [password, setPassword] = useState("");
//     const { setIsAdmin } = useContext(AdminContext); // Get the setIsAdmin function from context
//     const navigate = useNavigate();

//     const handleLogin = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post(
//                 `${API_BASE_URL}/api/admin/login`,
//                 { username, password }
//             );
//             localStorage.setItem("token", response.data.token); // Store the token
//             setIsAdmin(true); // Set isAdmin to true on successful login
//             navigate("/admin/dashboard"); // Redirect to admin dashboard
//         } catch (error) {
//             alert("Login failed. Please try again.");
//         }
//     };

//     return (
//         <div className='login-container'>
//             <h2 className='heading'>Admin Login</h2>
//             <form onSubmit={handleLogin}>
//                 <div className='input-container'>
//                     <input
//                         type='text'
//                         placeholder='Username'
//                         value={username}
//                         onChange={(e) => setUsername(e.target.value)}
//                     />
//                     <input
//                         type='password'
//                         placeholder='Password'
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                     />
//                     <button type='submit'>Login</button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default AdminLogin;

import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AdminContext } from "../context/AdminContext";
import "../styles/AdminLogin.css";
import API_BASE_URL from "../config";

const AdminLogin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { setIsAdmin } = useContext(AdminContext); // Get the setIsAdmin function from context
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/admin/login`,
                { username, password }
            );
            localStorage.setItem("token", response.data.token); // Store the token
            setIsAdmin(true); // Set isAdmin to true on successful login
            navigate("/admin/dashboard"); // Redirect to admin dashboard
        } catch (error) {
            console.error("Login error:", error); // Detailed error log

            if (error.response) {
                // Server responded with a status code other than 2xx
                alert(`Error: ${error.response.data.message}`);
            } else if (error.request) {
                // The request was made but no response was received
                alert("No response from server. Please try again later.");
            } else {
                // Something happened in setting up the request that triggered an Error
                alert("Login failed. Please try again.");
            }
        }
    };

    return (
        <div className='login-container'>
            <h2 className='heading'>Admin Login</h2>
            <form onSubmit={handleLogin}>
                <div className='input-container'>
                    <input
                        type='text'
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type='submit'>Login</button>
                </div>
            </form>
        </div>
    );
};

export default AdminLogin;
