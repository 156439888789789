import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            setTimeout(() => {
                navigate("/login"); // Redirect to login after 3 seconds
            }, 2000);
        }
    }, [token, navigate]);

    if (!token) {
        return (
            <div>
                You don't have the necessary permissions to view this page.
                Redirecting to login...
            </div>
        );
    }

    return children;
};

export default ProtectedRoute;
