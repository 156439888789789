import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import ContactPage from "./components/ContactPage";
import AboutPage from "./components/AboutPage";
import ApartmentsPage from "./components/ApartmentsPage";
import SingleApartment from "./components/SingleApartment";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import CreateApartment from "./components/CreateApartment";
import EditApartment from "./components/EditApartment";
import ProtectedRoute from "./components/ProtectedRoute";
import { AdminProvider } from "./context/AdminContext";
import OrderConfirmationPage from "./components/OrderConfirmationPage";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AGB from "./components/AGB";

const App = () => {
    return (
        <AdminProvider>
            <Router>
                <Routes>
                    {/* Public Routes */}
                    <Route path='/' element={<Homepage />} />
                    <Route path='/kontakt' element={<ContactPage />} />
                    <Route path='/about' element={<AboutPage />} />
                    <Route path='/apartments' element={<ApartmentsPage />} />
                    <Route path='/datenschutz' element={<PrivacyPolicy />} />
                    <Route path='/agb' element={<AGB />} />
                    <Route
                        path='/apartments/:id'
                        element={<SingleApartment />}
                    />
                    <Route
                        path='/order-confirmation'
                        element={<OrderConfirmationPage />}
                    />

                    {/* Admin Login */}
                    <Route path='/login' element={<AdminLogin />} />

                    {/* Protected Admin Routes */}
                    <Route
                        path='/admin/dashboard'
                        element={
                            <ProtectedRoute>
                                <AdminDashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='/admin/apartments/create'
                        element={
                            <ProtectedRoute>
                                <CreateApartment />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path='/admin/apartments/edit/:id'
                        element={
                            <ProtectedRoute>
                                <EditApartment />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
                <Footer />
            </Router>
        </AdminProvider>
    );
};

export default App;
