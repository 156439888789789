import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import { AdminContext } from "../context/AdminContext";
import "../styles/Navbar.css";

const Navbar = () => {
    const { isAdmin, setIsAdmin } = useContext(AdminContext);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem("token"); // Remove token from localStorage
        setIsAdmin(false); // Update global state to reflect admin logout
        navigate("/"); // Redirect to homepage after logout
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Toggle burger menu
    };

    return (
        <>
            <h1 className='NB-heading'>Giovannis Apartments</h1>

            {/* Burger Button for mobile view */}
            <button
                className={`NB-burger ${menuOpen ? "active" : ""}`}
                onClick={toggleMenu}
            >
                <span className='NB-burger-bar'></span>
                <span className='NB-burger-bar'></span>
                <span className='NB-burger-bar'></span>
            </button>

            {/* Navigation Menu */}
            <nav className={`NB-nav ${menuOpen ? "open" : ""}`}>
                <ul className='NB-navbar'>
                    <li>
                        <Link to='/' onClick={() => setMenuOpen(false)}>
                            Startseite
                        </Link>
                    </li>
                    <li>
                        <Link
                            to='/apartments'
                            onClick={() => setMenuOpen(false)}
                        >
                            Alle Apartments
                        </Link>
                    </li>
                    {/* <li>
                        <Link to='/about' onClick={() => setMenuOpen(false)}>
                            Über Uns
                        </Link>
                    </li> */}
                    <li>
                        <Link to='/kontakt' onClick={() => setMenuOpen(false)}>
                            Kontakt
                        </Link>
                    </li>

                    {isAdmin && (
                        <>
                            <li>
                                <Link
                                    to='/admin/dashboard'
                                    onClick={() => setMenuOpen(false)}
                                >
                                    Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/admin/apartments/create'
                                    onClick={() => setMenuOpen(false)}
                                >
                                    Neues Apartment
                                </Link>
                            </li>
                            <li>
                                <button
                                    onClick={() => {
                                        handleLogout();
                                        setMenuOpen(false);
                                    }}
                                >
                                    Ausloggen
                                </button>
                            </li>
                            <span className='NB-admin-context'>
                                Als Admin eingeloggt.
                            </span>
                        </>
                    )}
                </ul>
            </nav>

            {/* Backdrop for mobile menu */}
            {menuOpen && (
                <div
                    className='NB-backdrop'
                    onClick={() => setMenuOpen(false)} // Close when backdrop clicked
                ></div>
            )}
        </>
    );
};

export default Navbar;
