// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Navbar from "./Navbar";
// import "../styles/CreateApartment.css";
// import API_BASE_URL from "../config";

// const CreateApartment = () => {
//     const [title, setTitle] = useState("");
//     const [description, setDescription] = useState("");
//     const [location, setLocation] = useState("");
//     const [price, setPrice] = useState("");
//     const [imageFiles, setImageFiles] = useState([]); // Change to array
//     const [available, setAvailable] = useState(true);
//     const navigate = useNavigate();

//     const handleImageUpload = (e) => {
//         setImageFiles(Array.from(e.target.files)); // Capture all uploaded files
//     };

//     const handleCreateApartment = async (e) => {
//         e.preventDefault();
//         const token = localStorage.getItem("token");

//         const formData = new FormData();
//         formData.append("title", title);
//         formData.append("description", description);
//         formData.append("location", location);
//         formData.append("price", Number(price));
//         formData.append("available", available);

//         // Append all uploaded files to the form data
//         imageFiles.forEach((file) => {
//             formData.append("images", file); // Change 'image' to 'images'
//         });

//         try {
//             await axios.post(`${API_BASE_URL}/api/apartments`, formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     "Content-Type": "multipart/form-data",
//                 },
//             });

//             navigate("/admin/dashboard"); // Redirect to dashboard after creating
//         } catch (error) {
//             console.error("Error creating apartment:", error);
//             alert("Failed to create apartment");
//         }
//     };

//     return (
//         <div>
//             <Navbar />
//             <div className='create-apartment-container'>
//                 <h2 className='create-apartment-heading'>
//                     Create New Apartment
//                 </h2>
//                 <form
//                     onSubmit={handleCreateApartment}
//                     className='apartment-form'
//                 >
//                     <div className='form-group'>
//                         <input
//                             type='text'
//                             className='form-input'
//                             placeholder='Title'
//                             value={title}
//                             onChange={(e) => setTitle(e.target.value)}
//                             required
//                         />
//                     </div>

//                     <div className='form-group'>
//                         <input
//                             type='text'
//                             className='form-input'
//                             placeholder='Description'
//                             value={description}
//                             onChange={(e) => setDescription(e.target.value)}
//                             required
//                         />
//                     </div>

//                     <div className='form-group'>
//                         <input
//                             type='text'
//                             className='form-input'
//                             placeholder='Location'
//                             value={location}
//                             onChange={(e) => setLocation(e.target.value)}
//                             required
//                         />
//                     </div>

//                     <div className='form-group'>
//                         <input
//                             type='text'
//                             className='form-input'
//                             placeholder='Price'
//                             value={price}
//                             onChange={(e) => setPrice(e.target.value)}
//                             required
//                         />
//                     </div>

//                     {/* Image Upload */}
//                     <div className='form-group'>
//                         <label className='image-upload-label'>
//                             Upload Images
//                         </label>
//                         <input
//                             type='file'
//                             accept='image/*'
//                             onChange={handleImageUpload}
//                             className='image-upload-input'
//                             multiple // Allow multiple uploads
//                             required
//                         />
//                     </div>

//                     {/* Checkbox for availability */}
//                     <div className='form-group checkbox-group'>
//                         <label className='checkbox-label'>Available</label>
//                         <input
//                             type='checkbox'
//                             checked={available}
//                             onChange={(e) => setAvailable(e.target.checked)}
//                         />
//                     </div>

//                     <button type='submit' className='submit-btn'>
//                         Create Apartment
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default CreateApartment;

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "../styles/CreateApartment.css";
import API_BASE_URL from "../config";

const CreateApartment = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [price, setPrice] = useState("");
    const [imageFiles, setImageFiles] = useState([]); // Change to array
    const [available, setAvailable] = useState(true);
    const navigate = useNavigate();

    const handleImageUpload = (e) => {
        setImageFiles(Array.from(e.target.files)); // Capture all uploaded files
    };

    const handleCreateApartment = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("location", location);
        formData.append("price", Number(price));
        formData.append("available", available);

        // Append all uploaded files to the form data
        imageFiles.forEach((file) => {
            formData.append("images", file); // Change 'image' to 'images'
        });

        try {
            await axios.post(`${API_BASE_URL}/api/apartments`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            navigate("/admin/dashboard"); // Redirect to dashboard after creating
        } catch (error) {
            console.error("Error creating apartment:", error);
            alert("Failed to create apartment");
        }
    };

    return (
        <div>
            <Navbar />
            <div className='CNA-create-apartment-container'>
                <h2 className='CNA-create-apartment-heading'>
                    Neues Apartment
                </h2>
                <form
                    onSubmit={handleCreateApartment}
                    className='CNA-apartment-form'
                >
                    <div className='CNA-form-group'>
                        <input
                            type='text'
                            className='CNA-form-input'
                            placeholder='Titel'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>

                    <div className='CNA-form-group'>
                        <input
                            type='text'
                            className='CNA-form-input'
                            placeholder='Beschreibung'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>

                    <div className='CNA-form-group'>
                        <input
                            type='text'
                            className='CNA-form-input'
                            placeholder='Adresse'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                        />
                    </div>

                    <div className='CNA-form-group'>
                        <input
                            type='text'
                            className='CNA-form-input'
                            placeholder='Preis'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            required
                        />
                    </div>

                    {/* Image Upload */}
                    <div className='CNA-form-group'>
                        <label className='CNA-image-upload-label'>
                            Fotos hochladen
                        </label>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={handleImageUpload}
                            className='CNA-image-upload-input'
                            multiple // Allow multiple uploads
                            required
                        />
                    </div>

                    {/* Checkbox for availability */}
                    <div className='CNA-form-group CNA-checkbox-group'>
                        <label className='CNA-checkbox-label'>Verfügbar</label>
                        <input
                            type='checkbox'
                            checked={available}
                            onChange={(e) => setAvailable(e.target.checked)}
                        />
                    </div>

                    <button type='submit' className='CNA-submit-btn'>
                        Apartment erstellen
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateApartment;
