import React, { createContext, useState, useEffect } from "react";

// Create the context for admin
export const AdminContext = createContext();

// AdminProvider Component
export const AdminProvider = ({ children }) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // Check for admin token in localStorage
        const token = localStorage.getItem("token");
        if (token) {
            setIsAdmin(true); // User is logged in as Admin
        } else {
            setIsAdmin(false); // User is not logged in
        }
    }, []);

    return (
        <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
            {children}
        </AdminContext.Provider>
    );
};
