import React, { useState, useEffect } from "react";
import "../styles/ApartmentModal.css"; // Add styles for the modal

const ApartmentModal = ({
    showModal,
    setShowModal,
    apartment,
    onSave, // Function to call when saving (e.g., editing)
    onDelete, // Function to call when confirming deletion
    modalType, // Type of modal (edit, delete)
}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [location, setLocation] = useState("");
    const [price, setPrice] = useState("");
    const [available, setAvailable] = useState(true);

    // Pre-fill form data if editing
    useEffect(() => {
        if (apartment && modalType === "edit") {
            setTitle(apartment.title);
            setDescription(apartment.description);
            setLocation(apartment.location);
            setPrice(apartment.price);
            setAvailable(apartment.available);
        } else if (modalType === "create") {
            setTitle("");
            setDescription("");
            setLocation("");
            setPrice("");
            setAvailable(true);
        }
    }, [apartment, modalType]);

    const handleSave = async (e) => {
        e.preventDefault();

        const payload = {
            title,
            description,
            location,
            price: Number(price),
            available,
        };

        // Log the payload for debugging
        console.log("Payload being sent:", payload);

        await onSave(payload); // Call the parent component's save function
        setShowModal(false); // Close modal after saving
    };

    const handleDelete = async () => {
        onDelete(apartment); // Call the parent component's delete function
    };

    const handleClose = () => {
        setShowModal(false); // Close modal
    };

    const handleOverlayClick = (e) => {
        // Close modal when clicking on the overlay
        if (e.target === e.currentTarget) {
            handleClose();
        }
    };

    return (
        <>
            {showModal && (
                <div className='APM-modal-overlay' onClick={handleOverlayClick}>
                    <div className='APM-modal-content'>
                        <div className='APM-modal-header'>
                            <h3 className='APM-modal-title'>
                                {modalType === "edit"
                                    ? "Apartment bearbeiten"
                                    : modalType === "create"
                                    ? "Neues Apartment erstellen"
                                    : "Apartment löschen"}
                            </h3>
                            <button
                                className='APM-close-button'
                                onClick={handleClose}
                            >
                                &times;
                            </button>
                        </div>

                        {modalType === "delete" ? (
                            <>
                                <p>
                                    Soll dieses Apartment wirklich gelöscht
                                    werden?
                                </p>
                                <div className='APM-button-container'>
                                    <button onClick={handleDelete}>
                                        Bestätigen
                                    </button>
                                    <button onClick={handleClose}>
                                        Abbrechen
                                    </button>
                                </div>
                            </>
                        ) : (
                            <form onSubmit={handleSave}>
                                <div className='APM-input-field'>
                                    <label>Titel</label>
                                    <input
                                        type='text'
                                        placeholder='Title'
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className='APM-input-field'>
                                    <label>Beschreibung</label>
                                    <input
                                        type='text'
                                        placeholder='Description'
                                        value={description}
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className='APM-input-field'>
                                    <label>Adresse</label>
                                    <input
                                        type='text'
                                        placeholder='Location'
                                        value={location}
                                        onChange={(e) =>
                                            setLocation(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className='APM-input-field'>
                                    <label>Preis (€)</label>
                                    <input
                                        type='number'
                                        placeholder='Price'
                                        value={price}
                                        onChange={(e) =>
                                            setPrice(e.target.value)
                                        }
                                        required
                                    />
                                </div>
                                <div className='APM-radio-container'>
                                    <label className='availability-radio-label'>
                                        <input
                                            type='radio'
                                            value='available'
                                            checked={available}
                                            onChange={() => setAvailable(true)}
                                        />
                                        Verfügbar
                                    </label>
                                    <label className='availability-radio-label'>
                                        <input
                                            type='radio'
                                            value='unavailable'
                                            checked={!available}
                                            onChange={() => setAvailable(false)}
                                        />
                                        Belegt
                                    </label>
                                </div>
                                <div className='APM-button-container'>
                                    <button
                                        type='submit'
                                        className='APM-save-button'
                                    >
                                        {modalType === "edit"
                                            ? "Änderungen speichern"
                                            : "Erstellen"}
                                    </button>
                                    <button
                                        type='button'
                                        className='APM-cancel-button'
                                        onClick={handleClose}
                                    >
                                        Abbrechen
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default ApartmentModal;
