import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ImageCarousel from "./ImageCarousel"; // Ensure your ImageCarousel works as expected
import "../styles/SingleApartment.css";
import Navbar from "./Navbar";
import API_BASE_URL from "../config";

const SingleApartment = () => {
    const { id } = useParams();
    const [apartment, setApartment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [nights, setNights] = useState(0);
    const [endDate, setEndDate] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    useEffect(() => {
        const fetchApartment = async () => {
            try {
                const response = await axios.get(
                    `${API_BASE_URL}/api/apartments/${id}`
                );
                setApartment(response.data);
                setLoading(false);
            } catch (err) {
                setError("Fehler beim Abrufen der Wohnung Details.");
                setLoading(false);
            }
        };
        fetchApartment();
    }, [id]);

    useEffect(() => {
        if (apartment && nights) {
            setTotalPrice(nights * parseFloat(apartment.price));
        }
    }, [nights, apartment]);

    useEffect(() => {
        if (startDate && nights) {
            const calculatedEndDate = new Date(
                new Date(startDate).setDate(
                    new Date(startDate).getDate() + nights
                )
            )
                .toISOString()
                .split("T")[0];
            setEndDate(calculatedEndDate);
        }
    }, [startDate, nights]);

    const handleBookNow = () => {
        if (startDate) {
            setShowModal(true);
        } else {
            alert("Bitte wählen Sie ein Startdatum.");
        }
    };

    const handlePayPalApprove = async (data) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/capture-order`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    orderId: data.orderID,
                    amount: totalPrice.toFixed(2),
                    nights,
                    name,
                    email,
                    phone,
                    startDate,
                    endDate,
                    apartmentId: apartment._id,
                    apartmentTitle: apartment.title,
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Payment capture failed:", errorText);
                throw new Error("Payment capture failed");
            }

            const order = await response.json();
            navigate("/order-confirmation", {
                state: {
                    orderNumber: order.id,
                    apartmentTitle: apartment.title,
                    totalPrice: totalPrice.toFixed(2),
                    nights,
                    startDate,
                    endDate,
                    buyerInfo: {
                        name,
                        email,
                        phone: phone || "Nicht angegeben",
                    },
                },
            });
        } catch (error) {
            console.error("Payment capture failed: ", error);
            alert("Payment capture failed. Please try again.");
        }
    };

    if (loading) return <p>Wird geladen...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <Navbar />
            <div className='SA-container'>
                {/* Render Image Carousel if multiple images, otherwise just one image */}
                <div className='SA-image-container'>
                    {apartment.images && apartment.images.length > 1 ? (
                        <ImageCarousel images={apartment.images} />
                    ) : (
                        <img
                            src={apartment.images[0]}
                            alt={apartment.title}
                            className='SA-image'
                        />
                    )}
                </div>

                <div className='SA-details'>
                    <h2>{apartment.title}</h2>
                    <p className='SA-description'>{apartment.description}</p>
                    <p>
                        <strong>Adresse:</strong> {apartment.location}
                    </p>
                    <p>
                        <strong>Preis pro Nacht:</strong> €{apartment.price}
                    </p>

                    <div className='SA-booking'>
                        <label className='SA-label'>
                            Startdatum:
                            <input
                                type='date'
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className='SA-date-picker'
                                required
                            />
                        </label>

                        <label className='SA-label'>
                            Anzahl der Nächte:
                            <select
                                value={nights}
                                onChange={(e) =>
                                    setNights(Number(e.target.value))
                                }
                                className='SA-nights-dropdown'
                            >
                                <option value={0}>Nächte auswählen</option>
                                {[...Array(30).keys()].map((i) => (
                                    <option key={i} value={i + 1}>
                                        {i + 1} {i > 0 ? "Nächte" : "Nacht"}
                                    </option>
                                ))}
                            </select>
                        </label>

                        {endDate && (
                            <p
                                style={{
                                    border: "1px solid black",
                                    borderRadius: "8px",
                                    color: "black",
                                    padding: ".75rem",
                                }}
                            >
                                <span style={{ fontSize: ".85rem" }}>
                                    Ihr Check-Out ist am:
                                </span>{" "}
                                {new Date(endDate).toLocaleDateString("de-DE", {
                                    day: "2-digit", // stellt sicher, dass der Tag immer zweiziffrig angezeigt wird
                                    month: "2-digit", // stellt sicher, dass der Monat immer zweiziffrig angezeigt wird
                                    year: "numeric", // stellt sicher, dass das Jahr in vier Ziffern angezeigt wird
                                })}
                            </p>
                        )}

                        <div className='SA-user-info'>
                            <label className='SA-form-label'>
                                Name*:
                                <input
                                    type='text'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className='SA-input'
                                    required
                                />
                            </label>

                            <label className='SA-form-label'>
                                E-Mail*:
                                <input
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='SA-input'
                                    required
                                />
                            </label>

                            <label className='SA-form-label'>
                                Telefon{" "}
                                <span className='SA-optional'>(optional)</span>:
                                <input
                                    type='tel'
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className='SA-input'
                                />
                            </label>
                            <span className='SA-pflichtfeld'>
                                * Pflichtfelder
                            </span>
                        </div>

                        <button
                            className='SA-book-now-btn'
                            onClick={handleBookNow}
                            disabled={
                                !startDate || nights === 0 || !name || !email
                            } // Bedingung erweitert
                        >
                            Jetzt buchen
                        </button>
                    </div>

                    {showModal && (
                        <div className='modal'>
                            <div className='modal-content'>
                                <span
                                    className='close'
                                    onClick={() => setShowModal(false)}
                                >
                                    &times;
                                </span>
                                <h3
                                    style={{
                                        textAlign: "center",
                                        marginBlockEnd: ".5rem",
                                    }}
                                >
                                    Buchungsbestätigung
                                </h3>
                                <h4 style={{ textAlign: "center" }}>
                                    Bitte überprüfen Sie Ihre Angaben
                                </h4>
                                <p>
                                    <strong>Name:</strong> {name}
                                </p>
                                <p>
                                    <strong>Email:</strong> {email}
                                </p>
                                <p>
                                    <strong>Telefon:</strong>{" "}
                                    {phone || "Nicht angegeben"}
                                </p>
                                <p>
                                    <strong>Gesamtpreis:</strong> €
                                    {totalPrice.toFixed(2)} für {nights}{" "}
                                    {nights === 1 ? "Nacht" : "Nächte"}
                                </p>
                                <p>
                                    <strong className='SA-cho-context'>
                                        Check-Out:
                                    </strong>{" "}
                                    {new Date(endDate).toLocaleDateString(
                                        "de-DE",
                                        {
                                            day: "2-digit", // stellt sicher, dass der Tag immer zweiziffrig angezeigt wird
                                            month: "2-digit", // stellt sicher, dass der Monat immer zweiziffrig angezeigt wird
                                            year: "numeric", // stellt sicher, dass das Jahr in vier Ziffern angezeigt wird
                                        }
                                    )}
                                </p>

                                <PayPalScriptProvider
                                    options={{
                                        "client-id": clientID,
                                        currency: "EUR",
                                    }}
                                    key={clientID}
                                >
                                    <PayPalButtons
                                        createOrder={async () => {
                                            if (totalPrice <= 0) {
                                                alert(
                                                    "Der Gesamtpreis muss größer als null sein."
                                                );
                                                return;
                                            }

                                            const response = await fetch(
                                                `${API_BASE_URL}/api/create-order`,
                                                {
                                                    method: "POST",
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                    body: JSON.stringify({
                                                        amount: totalPrice.toFixed(
                                                            2
                                                        ),
                                                        nights,
                                                        name,
                                                        email,
                                                        phone,
                                                        apartmentTitle:
                                                            apartment.title,
                                                        startDate,
                                                        endDate,
                                                        apartmentId:
                                                            apartment._id,
                                                    }),
                                                }
                                            );
                                            const orderData =
                                                await response.json();

                                            if (!response.ok) {
                                                console.error(
                                                    "Bestellungscreation fehlgeschlagen:",
                                                    orderData
                                                );
                                                throw new Error(
                                                    "Fehler bei der Bestellungserstellung"
                                                );
                                            }

                                            return orderData.id;
                                        }}
                                        onApprove={handlePayPalApprove}
                                    />
                                </PayPalScriptProvider>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default SingleApartment;
