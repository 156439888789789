import React from "react";
import Slider from "react-slick";
import "../styles/Carousel.css"; // Ensure this CSS file exists

const ImageCarousel = ({ images }) => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    return (
        <div className='carousel-container'>
            <Slider {...sliderSettings}>
                {images.map((image, index) => (
                    <div key={index} className='carousel-slide'>
                        <img
                            src={image}
                            alt={`Apartment ${index + 1}`}
                            className='carousel-image'
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageCarousel;
