import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "./Navbar";
import "../styles/ApartmentsPage.css"; // Import the CSS file for styling
import API_BASE_URL from "../config";

const ApartmentsPage = () => {
    const [apartments, setApartments] = useState([]);
    const [allApartments, setAllApartments] = useState([]); // Store original apartments for resetting
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        price: "",
        location: "",
        available: "",
    });
    const [sortOrder, setSortOrder] = useState("asc"); // Sort by ascending order

    useEffect(() => {
        const fetchApartments = async () => {
            const response = await axios.get(`${API_BASE_URL}/api/apartments`);
            setApartments(response.data);
            setAllApartments(response.data); // Store original apartments
        };
        fetchApartments();
    }, []);

    // Function to toggle the modal
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    // Function to handle filter changes
    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    // Function to handle sorting changes
    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    // Function to apply filters and sorting
    const applyFilters = () => {
        let filteredApartments = [...allApartments]; // Use the original list for filtering

        // Filter by price if specified
        if (filters.price) {
            filteredApartments = filteredApartments.filter(
                (apartment) => apartment.price <= parseInt(filters.price)
            );
        }

        // Filter by location if specified
        if (filters.location) {
            filteredApartments = filteredApartments.filter((apartment) =>
                apartment.location
                    .toLowerCase()
                    .includes(filters.location.toLowerCase())
            );
        }

        // Filter by availability if specified
        if (filters.available) {
            filteredApartments = filteredApartments.filter(
                (apartment) => String(apartment.available) === filters.available
            );
        }

        // Sort by price
        filteredApartments = filteredApartments.sort((a, b) =>
            sortOrder === "asc" ? a.price - b.price : b.price - a.price
        );

        setApartments(filteredApartments);
        toggleModal(); // Close the modal after applying filters
    };

    // Function to reset filters and sorts
    const resetFilters = () => {
        setFilters({
            price: "",
            location: "",
            available: "",
        });
        setSortOrder("asc");
        setApartments(allApartments); // Reset to original apartments immediately
    };

    // Function to close the modal when clicking the backdrop
    const handleBackdropClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            toggleModal();
        }
    };

    return (
        <>
            <Navbar />
            <h2 className='page-title'>All Apartments</h2>

            {/* Filter & Sort Button (Centered) */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0",
                }}
            >
                <button className='filter-btn' onClick={toggleModal}>
                    Filtern & Sortieren
                </button>
            </div>

            <div className='apartments-grid'>
                {apartments.map((apartment) => (
                    <div className='apartment-card' key={apartment._id}>
                        <Link
                            to={`/apartments/${apartment._id}`}
                            className='apartment-link'
                        >
                            {/* Use the first image from the images array if available */}
                            {apartment.images && apartment.images.length > 0 ? (
                                <img
                                    src={apartment.images[0]} // Display the first image
                                    alt={apartment.title}
                                    className='apartment-image'
                                />
                            ) : (
                                <div className='no-image-placeholder'>
                                    Kein Bild verfügbar
                                </div>
                            )}
                            <div className='apartment-info'>
                                <h3 className='apartment-title'>
                                    {apartment.title}
                                </h3>
                                <p className='apartment-location'>
                                    {apartment.location}
                                </p>
                                <p className='apartment-price'>
                                    €{apartment.price} / Nacht
                                </p>
                                <p
                                    className={`apartment-availability ${
                                        apartment.available
                                            ? ""
                                            : "not-available"
                                    }`}
                                >
                                    {apartment.available
                                        ? "Verfügbar"
                                        : "Belegt"}
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {/* Modal for Filters & Sorting */}
            {isModalOpen && (
                <div className='modal-overlay' onClick={handleBackdropClick}>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3>Filtern & Sortieren</h3>
                            <button
                                className='close-modal'
                                onClick={toggleModal}
                            >
                                x
                            </button>
                        </div>
                        <div className='filter-section'>
                            <label>Maximaler Preis:</label>
                            <input
                                type='number'
                                name='price'
                                value={filters.price}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className='filter-section'>
                            <label>Verfügbarkeit:</label>
                            <select
                                name='available'
                                value={filters.available}
                                onChange={handleFilterChange}
                            >
                                <option value=''>Egal</option>
                                <option value='true'>Verfügbar</option>
                                <option value='false'>Belegt</option>
                            </select>
                        </div>
                        <div className='sort-section'>
                            <label>Nach Preis sortieren:</label>
                            <select
                                value={sortOrder}
                                onChange={handleSortChange}
                            >
                                <option value='asc'>Aufsteigend</option>
                                <option value='desc'>Absteigend</option>
                            </select>
                        </div>

                        <div className='modal-buttons'>
                            <button onClick={applyFilters}>Anwenden</button>
                            <button onClick={resetFilters}>Reset</button>
                            <button onClick={toggleModal}>Abbrechen</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ApartmentsPage;

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Navbar from "./Navbar";
// import "../styles/ApartmentsPage.css"; // Import the CSS file for styling
// import API_BASE_URL from "../config";

// const ApartmentsPage = () => {
//     const [apartments, setApartments] = useState([]);
//     const [allApartments, setAllApartments] = useState([]); // Store original apartments for resetting
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [filters, setFilters] = useState({
//         price: "",
//         location: "",
//         available: "",
//     });
//     const [sortOrder, setSortOrder] = useState("asc"); // Sort by ascending order

//     useEffect(() => {
//         const fetchApartments = async () => {
//             const response = await axios.get(`${API_BASE_URL}/api/apartments`);
//             setApartments(response.data);
//             setAllApartments(response.data); // Store original apartments
//         };
//         fetchApartments();
//     }, []);

//     // Function to toggle the modal
//     const toggleModal = () => {
//         setIsModalOpen(!isModalOpen);
//     };

//     // Function to handle filter changes
//     const handleFilterChange = (e) => {
//         setFilters({
//             ...filters,
//             [e.target.name]: e.target.value,
//         });
//     };

//     // Function to handle sorting changes
//     const handleSortChange = (e) => {
//         setSortOrder(e.target.value);
//     };

//     // Function to apply filters and sorting
//     const applyFilters = () => {
//         let filteredApartments = [...allApartments]; // Use the original list for filtering

//         // Filter by price if specified
//         if (filters.price) {
//             filteredApartments = filteredApartments.filter(
//                 (apartment) => apartment.price <= parseInt(filters.price)
//             );
//         }

//         // Filter by location if specified
//         if (filters.location) {
//             filteredApartments = filteredApartments.filter((apartment) =>
//                 apartment.location
//                     .toLowerCase()
//                     .includes(filters.location.toLowerCase())
//             );
//         }

//         // Filter by availability if specified
//         if (filters.available) {
//             filteredApartments = filteredApartments.filter(
//                 (apartment) => String(apartment.available) === filters.available
//             );
//         }

//         // Sort by price
//         filteredApartments = filteredApartments.sort((a, b) =>
//             sortOrder === "asc" ? a.price - b.price : b.price - a.price
//         );

//         setApartments(filteredApartments);
//         toggleModal(); // Close the modal after applying filters
//     };

//     // Function to reset filters and sorts
//     const resetFilters = () => {
//         setFilters({
//             price: "",
//             location: "",
//             available: "",
//         });
//         setSortOrder("asc");
//         setApartments(allApartments); // Reset to original apartments immediately
//     };

//     // Function to close the modal when clicking the backdrop
//     const handleBackdropClick = (e) => {
//         if (e.target.classList.contains("APP-modal-overlay")) {
//             toggleModal();
//         }
//     };

//     return (
//         <>
//             <Navbar />
//             <h2 className='APP-page-title'>Alle Wohnungen</h2>

//             {/* Filter & Sort Button (Centered) */}
//             <div className='APP-filter-button-container'>
//                 <button className='APP-filter-btn' onClick={toggleModal}>
//                     Filtern & Sortieren
//                 </button>
//             </div>

//             <div className='APP-apartments-grid'>
//                 {apartments.map((apartment) => (
//                     <div className='APP-apartment-card' key={apartment._id}>
//                         <Link
//                             to={`/apartments/${apartment._id}`}
//                             className='APP-apartment-link'
//                         >
//                             {/* Use the first image from the images array if available */}
//                             {apartment.images && apartment.images.length > 0 ? (
//                                 <img
//                                     src={apartment.images[0]}
//                                     alt={apartment.title}
//                                     className='APP-apartment-image'
//                                 />
//                             ) : (
//                                 <div className='APP-no-image-placeholder'>
//                                     Kein Bild verfügbar
//                                 </div>
//                             )}
//                             <div className='APP-apartment-info'>
//                                 <h3 className='APP-apartment-title'>
//                                     {apartment.title}
//                                 </h3>
//                                 <p className='APP-apartment-location'>
//                                     {apartment.location}
//                                 </p>
//                                 <p className='APP-apartment-price'>
//                                     €{apartment.price} / Nacht
//                                 </p>
//                                 <p
//                                     className={`APP-apartment-availability ${
//                                         apartment.available
//                                             ? ""
//                                             : "APP-not-available"
//                                     }`}
//                                 >
//                                     {apartment.available
//                                         ? "Verfügbar"
//                                         : "Belegt"}
//                                 </p>
//                             </div>
//                         </Link>
//                     </div>
//                 ))}
//             </div>

//             {/* Modal for Filters & Sorting */}
//             {isModalOpen && (
//                 <div
//                     className='APP-modal-overlay'
//                     onClick={handleBackdropClick}
//                 >
//                     <div className='APP-modal-content'>
//                         <div className='APP-modal-header'>
//                             <h3>Filtern & Sortieren</h3>
//                             <button
//                                 className='APP-close-modal'
//                                 onClick={toggleModal}
//                             >
//                                 ×
//                             </button>
//                         </div>
//                         <div className='APP-filter-section'>
//                             <label>Maximaler Preis (€):</label>
//                             <input
//                                 type='number'
//                                 name='price'
//                                 value={filters.price}
//                                 onChange={handleFilterChange}
//                                 placeholder='Max Preis'
//                             />
//                         </div>
//                         <div className='APP-filter-section'>
//                             <label>Verfügbarkeit:</label>
//                             <select
//                                 name='available'
//                                 value={filters.available}
//                                 onChange={handleFilterChange}
//                             >
//                                 <option value=''>Egal</option>
//                                 <option value='true'>Verfügbar</option>
//                                 <option value='false'>Belegt</option>
//                             </select>
//                         </div>
//                         <div className='APP-sort-section'>
//                             <label>Nach Preis sortieren:</label>
//                             <select
//                                 value={sortOrder}
//                                 onChange={handleSortChange}
//                             >
//                                 <option value='asc'>Aufsteigend</option>
//                                 <option value='desc'>Absteigend</option>
//                             </select>
//                         </div>

//                         <div className='APP-modal-buttons'>
//                             <button onClick={applyFilters}>Anwenden</button>
//                             <button onClick={resetFilters}>Zurücksetzen</button>
//                             <button onClick={toggleModal}>Abbrechen</button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

// export default ApartmentsPage;
